import React from 'react'
import css from './stylesheets/ReadingContents.module.scss'

export const ReadingContents = (props) => {
    const { readings, isSpMode } = props

    if (!readings) return null

    const convertToVw = (imageWidth, scale = 0.72, baseWidth = 1000) => {
        return (imageWidth / baseWidth) * scale * 100 + "vw"
    }

    const readingContents = readings.map((item) => {
        const fldNameList = item.fieldGroupName.split('_')
        const fldName = fldNameList[fldNameList.length - 1]
        let fldContent;
        switch (fldName) {
            case 'Headline1':
                fldContent = {
                    type: 'h1',
                    data: item.headline1Text,
                    isItalic: item.headline1IsItalic
                }
                break
            case 'Headline2':
                fldContent = {
                    type: 'h2',
                    data: item.headline2Text,
                    isItalic: item.headline2IsItalic
                }
                break
            case 'Paragraph':
                fldContent = {
                    type: 'p',
                    data: item.paragraphText
                }
                break
            case 'Figure': {
                let pos = {
                    width: convertToVw(Number(item.width)),
                    left: convertToVw(item.left === null ? 0 : Number(item.left)),
                    top: convertToVw(item.top === null ? 0 : Number(item.top)),
                    widthSp: convertToVw(Number(item.widthSp), 1),
                    leftSp: convertToVw(item.leftSp === null ? 0 : Number(item.leftSp), 1),
                    topSp: convertToVw(item.topSp === null ? 0 : Number(item.topSp), 1),
                }
                let style = {}
                if (isSpMode) {
                    style = {
                        width: pos.width ? pos.width : `100%`,
                        marginLeft: pos.leftSp ? pos.leftSp : 0,
                        marginTop: pos.topSp ? pos.topSp : 0,
                    }
                } else {
                    style = {
                        width: pos.width ? pos.width : `100%`,
                        marginLeft: pos.left ? pos.left : 0,
                        marginTop: pos.top ? pos.top : 0,
                    }
                }
                fldContent = {
                    type: 'imgSrc',
                    data: item.figureImage.mediaItemUrl,
                    caption: item.figureImage.caption,
                    style: style,
                }
                break
            }
            case 'Embed':
                fldContent = {
                    type: 'embedUrl',
                    data: item.embedUrl
                }
                break
            case 'Spacer':
                fldContent = {
                    type: 'spacer',
                    data: item.spacer
                }
                break
            default:
                fldContent = ''
        }
        return fldContent
    })

    const block = readingContents.map((item, index) => {
        if (item.type === 'h1') {
            return <h1 className={item.isItalic ? css.blockTtlLv1 + ' ' + css.blockTtlItalic : css.blockTtlLv1} key={index}>{item.data}</h1>
        }
        if (item.type === 'h2') {
            return <h2 className={item.isItalic ? css.blockTtlLv2 + ' ' + css.blockTtlItalic : css.blockTtlLv2} key={index}>{item.data}</h2>
        }
        if (item.type === 'p') {
            return (
                <div
                    className={css.blockText}
                    key={index}
                    dangerouslySetInnerHTML={{ __html: item.data }}
                />
            )
        }
        if (item.type === 'imgSrc') {
            return (
                <div className={css.blockFigureWrap} key={index}>
                    <figure
                        className={css.blockFigure}
                        style={item.style}
                    >
                        <img src={item.data} alt=""/>
                        {item.caption ? (<figcaption
                            dangerouslySetInnerHTML={{ __html: item.caption }}
                        />) : null}
                    </figure>
                </div>
            )
        }
        if (item.type === 'embedUrl') {
            const parser = new URL(item.data)
            const path = parser.pathname
            const [, youtubeId] = path.split('/')

            return (
                <div key={index} className={css.blockEmbed}>
                    <div className={css.blockEmbedWrapper}>
                        <iframe
                            width='560'
                            height='315'
                            src={`https://www.youtube.com/embed/${youtubeId}`}
                            frameBorder='0'
                            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                            allowFullScreen
                            title={`mov-${index}`}/>
                    </div>
                </div>
            )
        }
        if (item.type === 'spacer') {
            return <div style={ item.data < 0 ? {marginTop: item.data + 'vw'} : {marginBottom: item.data + 'vw'} } key={index}/>
        }
        return null
    })

    return (
        <div className={`${css.contentBoxBottom} ${css.readingContents}`}>{block}</div>
    )
}
