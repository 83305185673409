import React from "react"
import LayoutVenue from "../components/LayoutVenue"
import { graphql } from "gatsby"
import Seo from "../components/Seo"
import css from "./stylesheets/PostTemplate.module.scss"

export const pageQuery = graphql`
  query($slug: String!) {
    wpSkwat(slug: { eq: $slug }) {
      title
      slug
      acfSkwat {
        isclosed
        color
        background {
          color
          image {
            mediaItemUrl
            caption
          }
        }
        schedule {
          date
          time
        }
        tenantData {
          name
          lat
          lng
          location
          googleMapLink
          name
          url
          logo {
            mediaItemUrl
            caption
          }
          extra
        }
        images {
          image {
            mediaItemUrl
            caption
          }
          width
          widthSp
          left
          leftSp
          top
          topSp
          zIndex
        }
      }
      acfReadings {
        fieldGroupName
        readings {
          ... on WpSkwat_Acfreadings_Readings_Headline1 {
            fieldGroupName
            headline1Text
            headline1IsItalic
          }
          ... on WpSkwat_Acfreadings_Readings_Headline2 {
            fieldGroupName
            headline2Text
            headline2IsItalic
          }
          ... on WpSkwat_Acfreadings_Readings_Paragraph {
            fieldGroupName
            paragraphText
          }
          ... on WpSkwat_Acfreadings_Readings_Figure {
            fieldGroupName
            figureImage {
              mediaItemUrl
              caption
            }
            left
            leftSp
            top
            topSp
            width
          }
          ... on WpSkwat_Acfreadings_Readings_Embed {
            embedUrl
            fieldGroupName
          }
          ... on WpSkwat_Acfreadings_Readings_Spacer {
            fieldGroupName
            spacer
          }
        }
      }
      skwatTypes {
        nodes {
          name
          termTaxonomyId
        }
      }
    }
  }
`

export default props => {
  const { title, slug, acfSkwat, acfReadings } = props.data.wpSkwat

  const randomImages = acfSkwat.images.map(
    ({ image, width, left, top, widthSp, leftSp, topSp, zIndex }) => {
      return {
        src: image.mediaItemUrl,
        width: LayoutVenue.convertToVw(Number(width)),
        left: LayoutVenue.convertToVw(left === null ? 0 : Number(left)),
        top: LayoutVenue.convertToVw(top === null ? 0 : Number(top)),
        widthSp: LayoutVenue.convertToVw(Number(widthSp), 1),
        leftSp: LayoutVenue.convertToVw(
          leftSp === null ? 0 : Number(leftSp),
          1
        ),
        topSp: LayoutVenue.convertToVw(topSp === null ? 0 : Number(topSp), 1),
        zIndex: Number(zIndex),
      }
    }
  )

  // const message = acfSkwat.isclosed && 'CLOSED'
  const message = ""
  const tenantoLogo =
    acfSkwat.tenantData.logo && acfSkwat.tenantData.logo.mediaItemUrl
  const tenantoLink = acfSkwat.tenantData.logo && acfSkwat.tenantData.url
  const bgImage = acfSkwat.background.image
    ? acfSkwat.background.image.mediaItemUrl
    : "none"

  let extraContent = acfSkwat.tenantData.extra
  extraContent = extraContent && extraContent.replace(/style="([^"]*)"/g, "")

  return (
    <div className={slug} style={{ backgroundImage: `url(${bgImage})` }}>
      <LayoutVenue
        keycolor={acfSkwat.background.color}
        colorMode={acfSkwat.color}
        tenantLogo={tenantoLogo}
        tenantLink={tenantoLink}
        message={message}
        googleMapLink={acfSkwat.tenantData.googleMapLink}
        randomImages={randomImages}
        readings={acfReadings.readings}
      >
        <div>
          <Seo lang={`en`} title={title} />
          <h3 className={`skwatName`}>{acfSkwat.tenantData.name}</h3>
          <p className={`skwatLocation`}>{acfSkwat.tenantData.location}</p>
          <p>
            {acfSkwat.schedule.date}
            {acfSkwat.schedule.time && (
              <span>
                <br />
                {acfSkwat.schedule.time}
              </span>
            )}
          </p>
          {extraContent ? (
            <div
              className={css.extraContent}
              dangerouslySetInnerHTML={{ __html: extraContent }}
            />
          ) : null}
        </div>
      </LayoutVenue>
    </div>
  )
}
