import React, { Component } from "react"
import CommonHeader from "./CommonHeader"
import css from "./stylesheets/LayoutVenue.module.scss"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { ReadingContents } from "./ReadingContents";

class LayoutVenue extends Component {
  // 表示サイズ領域を 1000px とした場合の換算を行います。
  // sketch や XD で幅 1000px のキャンバスに自由レイアウトされた状態をウェブサイト側での vw の単位に変換します。
  static convertToVw = (imageWidth, scale = 0.5, baseWidth = 1000) => {
    return (imageWidth / baseWidth) * scale * 100 + "vw"
  }

  /**
   *
   * @param props
   */
  constructor(props) {
    super(props)
    this.state = {
      isSpMode: null,
    }
    this._spChecker = React.createRef()
  }

  /**
   *
   */
  componentDidMount() {
    if (typeof window !== "undefined") {
      this.handleWindowResize = e => {
        this.doResize()
      }
      window.addEventListener("resize", this.handleWindowResize, true)
    }
    setTimeout(() => {
      this.doResize()
    }, 1)
  }

  /**
   *
   */
  doResize() {
    if (window) {
      try {
        const isSpMode =
          document.defaultView.getComputedStyle(this._spChecker.current, null)
            .display !== `none`

        this.setState(state => ({
          isSpMode: isSpMode,
        }))
      } catch (e) {}
    }
  }

  render() {
    const style = {
      backgroundColor: this.props.keycolor ? this.props.keycolor : 'transparent',
    }

    let messageLayer = ""
    if (this.props.message) {
      messageLayer = (
        <div className={css.status}>
          <span>{this.props.message}</span>
        </div>
      )
    }

    let randomImages = []

    for (let i = 0; i < this.props.randomImages.length; i += 1) {
      const img = this.props.randomImages[i]
      let style = {}
      if (this.state.isSpMode) {
        style = {
          width: img.widthSp ? img.widthSp : `100%`,
          marginLeft: img.leftSp ? img.leftSp : 0,
          marginTop: img.topSp ? img.topSp : 0,
          zIndex: img.zIndex ? img.zIndex : 0,
        }
      } else {
        style = {
          width: img.width ? img.width : `100%`,
          marginLeft: img.left ? img.left : 0,
          marginTop: img.top ? img.top : 0,
          zIndex: img.zIndex ? img.zIndex : 0,
        }
      }

      randomImages.push(
        <div key={`carousel${i}`} className={css.imgBox} style={style}>
          <img src={img.src} alt={``} />
        </div>
      )
    }

    let textColorClass = this.props.colorMode === `#ffffff` ? `is-white-text` : ``



    return (
      <div style={style} className={css.base + ` ` + textColorClass}>
        <CommonHeader colorMode={this.props.colorMode} />

        <div className={css.contentBox}>
          <div className={css.contentBoxLeft}>

            <div className={css.content}>
              {this.props.children}
              {this.props.tenantLogo ?
                  this.props.tenantLink ? (
                        <div className={css.eventLogo}>
                          <OutboundLink href={this.props.tenantLink} target={`_blank`}>
                            <img src={this.props.tenantLogo} className={`tenantLogo`} alt="" />
                          </OutboundLink>
                        </div>
                    ) : (
                        <div className={css.eventLogo}>
                          <img src={this.props.tenantLogo} alt="" />
                        </div>
                    )
               : null}
            </div>
          </div>
          <div className={css.contentBoxRight}>{randomImages}</div>
          <ReadingContents isSpMode={this.state.isSpMode} readings={this.props.readings} />
        </div>
        {messageLayer}
        <div className={`onlySp`} ref={this._spChecker} />
      </div>
    )
  }
}

export default LayoutVenue
